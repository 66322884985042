import React from "react";

function CalendarIcon({ props }) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#fff"
      d="M20.4 2.4H18V1.2a1.2 1.2 0 10-2.4 0v1.2H8.4V1.2a1.2 1.2 0 00-2.4 0v1.2H3.6A3.6 3.6 0 000 6v14.4A3.6 3.6 0 003.6 24h16.8a3.6 3.6 0 003.6-3.6V6a3.6 3.6 0 00-3.6-3.6zm1.2 18a1.2 1.2 0 01-1.2 1.2H3.6a1.2 1.2 0 01-1.2-1.2V12h19.2v8.4zm0-10.8H2.4V6a1.2 1.2 0 011.2-1.2H6V6a1.2 1.2 0 002.4 0V4.8h7.2V6A1.2 1.2 0 1018 6V4.8h2.4A1.2 1.2 0 0121.6 6v3.6z"
    ></path>
  </svg>
  );
}

export default CalendarIcon;
