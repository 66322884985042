import React from "react";

const HomeIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 20.025v-7.73c0-.538-.111-1.07-.326-1.564a3.951 3.951 0 0 0-.927-1.309L13.374 2.54A2.013 2.013 0 0 0 12 2c-.511 0-1.003.193-1.374.54L3.253 9.422a3.95 3.95 0 0 0-.927 1.309A3.909 3.909 0 0 0 2 12.295v7.73c0 .524.21 1.026.586 1.396.375.37.884.579 1.414.579h16c.53 0 1.04-.208 1.414-.579.375-.37.586-.872.586-1.396Z"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default HomeIcon;
