import React from "react";

function TickIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="54"
      fill="none"
      viewBox="0 0 54 54"
    >
      <circle
        cx="27"
        cy="27"
        r="25.5"
        stroke="#2E9533"
        strokeWidth="3"
      ></circle>
      <path
        stroke="#2E9533"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M30.098 36.749l2.944 3.036 5.888-5.06M22.248 16.508H16.36v23.277h8.832m11.775-10.12V16.508H31.08m-8.831-2.125h8.831v7.084h-8.831v-7.084z"
      ></path>
    </svg>
  );
}

export default TickIcon;
