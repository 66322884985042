import React from "react";

function RecentIcon({ props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_136_134)">
        <path
          fill="#fff"
          d="M12.694 5.33H11.31c-.127 0-.23.1-.23.223v7.683c0 .073.034.14.095.182l4.757 3.368a.235.235 0 00.322-.047l.823-1.089a.215.215 0 00-.049-.312l-4.103-2.877V5.552a.227.227 0 00-.23-.223zM19.02 7.43l4.513 1.069c.144.033.285-.073.285-.215l.023-4.507c0-.187-.222-.293-.372-.176l-4.535 3.435a.217.217 0 00-.024.332c.03.03.068.052.11.062zm4.826 8.403l-1.631-.545a.237.237 0 00-.174.01.226.226 0 00-.117.124 9.795 9.795 0 01-2.357 3.56 10.183 10.183 0 01-3.237 2.12 10.409 10.409 0 01-3.963.775c-1.375 0-2.708-.26-3.962-.776a10.182 10.182 0 01-3.238-2.118 9.886 9.886 0 01-2.184-3.14A9.606 9.606 0 012.188 12c0-1.334.267-2.629.8-3.846a9.795 9.795 0 012.184-3.14 10.32 10.32 0 017.197-2.894c1.376 0 2.708.26 3.963.777a10.18 10.18 0 014.06 3.02l1.721-1.307c-2.265-2.807-5.79-4.613-9.75-4.61C5.468.003-.068 5.433.001 12.123.07 18.695 5.58 24 12.369 24c5.338 0 9.885-3.282 11.617-7.878a.226.226 0 00-.14-.29z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_136_134">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default RecentIcon;