import React from "react";

function PendingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      fill="none"
      viewBox="0 0 45 45"
    >
      <path
        fill="#8C8C8C"
        d="M22.477 0C10.057 0 0 10.08 0 22.5S10.057 45 22.477 45C34.92 45 45 34.92 45 22.5S34.92 0 22.477 0zm.023 40.5c-9.945 0-18-8.055-18-18s8.055-18 18-18 18 8.055 18 18-8.055 18-18 18zm1.125-29.25H20.25v13.5l11.813 7.087 1.687-2.767-10.125-6.008V11.25z"
      ></path>
    </svg>
  );
}

export default PendingIcon;
